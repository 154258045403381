<template>
   <div>
      <div>
        <div class="pesquisa-title">{{ $t('historic.title') }}</div>
         <div class="pesquisa-container">
          <div class="pesquisa-card">
            <i class="fa fa-search icon-background"></i>
            <div class="search-line"></div>
            <!-- <input class="search-input" type="text" placeholder="Pesquisar"/> -->
            <b-form-select class="w-100" @change="onChangeFiltro($event)" v-model="filtro" v-if="typeExpenses === 0">
              <b-form-select-option disabled :value="null" :label="$t('options.select')"></b-form-select-option>
              <b-form-select-option-group :label="$t(`options.personalExpenses`)">
                <b-form-select-option v-for="data in $t('options.subcategoriestravel')" :key="data.value" :value="data">{{ data.text }}</b-form-select-option>
              </b-form-select-option-group>
              <b-form-select-option-group :label="$t('options.vehicleExpenses')">
                <b-form-select-option v-for="data in $t('options.subcategoriaVehicle')" :key="data.value" :value="data">{{ data.text }}</b-form-select-option>
              </b-form-select-option-group>
            </b-form-select>
            <b-form-select v-model="filtro" @change="onChangeFiltro($event)" v-else>
              <b-form-select-option :value="null">{{ $t('options.selectTypeExpense') }}</b-form-select-option>
                <b-form-select-option v-for="data in  $t('options.subcategoriesmiscellaneous')" :key="data.value" :value="data">{{ data.text }}</b-form-select-option>
            </b-form-select>
            <!-- <select v-model="filtro" class="form-filtro" @change="onChangeFiltro($event)" v-if="typeExpenses === 0">
                                <option :value="null" hidden>{{ $t('historic.options.select') }}</option>
                                <option v-for="data in  $t('options.subcategoriestravel')" :key="data.value" :value="data.value">{{data.text}}</option>
            </select>
            <select v-model="filtro" class="form-filtro" @change="onChangeFiltro($event)" v-else>
                                <option :value="null" hidden>{{ $t('historic.options.select') }}</option>
                                <option v-for="data in $t('options.subcategoriesmiscellaneous')" :key="data.value" :value="data.value">{{data.text}}</option>
            </select> -->
            <div @click="Atualiza" v-if="filtro !== null">
            <button class="fa fa-times" aria-hidden="true" ></button>
            </div>
          </div>
              <!-- <b-button class="button-filtro"></b-button> -->
         </div>
      </div>
      <div v-if="this.typeExpenses === 1">
        <div v-for="(expense, index) in expenses" v-bind:key="index" class="consulta-data-container">
           <div>
                <div class="data-container"> {{expense.dataDespesa}}</div>
                <div class="data-card-container">
                    <div v-for="(dataDespesa, index) in (expense.despesasEncaminhada)" v-bind:key="index" class="subcategoria-container mb-2">
                      <div class="statusdespesas" :style="{'background': dataDespesa.colorStatusDespesa}"></div>
                      <div class="justifique titulo-container upperCase">
                        <div class="justifique alinha-inicio">
                          <b>{{ $t('historic.technic') }} </b> : {{dataDespesa.nomeTecnico}}
                        </div>
                        <div v-if="dataDespesa.statusDespesa >= '1'">
                            <!-- <div class="justifique alinha-fim" v-if="dataDespesa.statusDespesa === 2">
                              AG. APROVAÇÃO CONTROLADORIA
                            </div> -->
                            <div class="justifique alinha-fim" :style="{'color': dataDespesa.colorStatusDespesa}">
                                {{dataDespesa.statusDespesaDescricaoTraducao}}
                            </div>
                            <!-- <div class="justifique alinha-fim" v-else>
                              {{dataDespesa.statusDespesaDescricaoTraducao}}
                            </div> -->
                        </div>

                      </div>
                      <div class="refeicao-container">
                        <div class="titulo-container upperCase"><b>{{dataDespesa.categoriaDescricaoTraducao}}</b>  </div>
                          <div class="gasto-container-negativo" style="font-weight: 500">
                            <div v-if="$i18n.locale === 'ptbr'">{{`${dataDespesa.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}}</div>
                            <div v-else>{{`CLP ${dataDespesa.valor.toLocaleString('es-CL', {  currency: 'CLP' , minimumFractionDigits: 2})}`}}</div>
                          </div>
                      </div>
                      <div class="motivo-container" v-if="dataDespesa.statusDespesa >=5 && dataDespesa.dataMotivoRecusa !== null">
                        <div>
                          <div class="mb-2"> <b>Data da Reprova :</b> {{ dataDespesa.dataMotivoRecusaFormatada }}</div>
                          <div>
                            <div class="mr-2 mb-1"> <b>Motivo :</b> {{ dataDespesa.motivoRecusa !== null ? dataDespesa.motivoRecusa : "Sem Motivo" }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="totalDia-container">
                        <div class="titulo-container">{{ $t('consultation.total_daily') }}</div>
                        <div class="gasto-container-negativo"> {{calTotal(expense)}} </div>
                    </div>
                </div>
            </div>
          </div>
      </div>

      <div v-else>
        <div v-for="(expense, index) in expenses" v-bind:key="index" class="consulta-data-container">
          <div>
                <div class="data-container"> {{expense.dataDespesa}}</div>
                <div class="data-card-container">
                    <div v-for="(dataDespesa, index) in (expense.despesasEncaminhada)" v-bind:key="index" class="subcategoria-container mb-2">
                      <div class="statusdespesas" :style="{'background': dataDespesa.colorStatusDespesa}"></div>
                      <div class="justifique titulo-container upperCase">
                        <div class="justifique alinha-inicio">
                          {{ $t('historic.coordinator') }} : {{dataDespesa.nomeCoordenador}}
                        </div>
                        <div class="justifique alinha-fim" v-if="dataDespesa.statusDespesa >= 1">
                              {{dataDespesa.statusDespesaDescricaoTraducao}}
                        </div>

                      </div>
                      <div class="refeicao-container">
                        <!-- <div class="titulo-container upperCase">{{dataDespesa.categoria === 0 ? dataDespesa.despesaDescricao : dataDespesa.descricaoSubCategoriaVeiculo}}  </div> -->
                        <div class="titulo-container upperCase">{{dataDespesa.categoria === 0 ? dataDespesa.subCategoriaDescricaoTraducao : dataDespesa.subCategoriaVeiculoDescricaoTraducao}}  </div>
                          <div class="gasto-container-negativo" style="font-weight: 500">
                            <div v-if="$i18n.locale === 'ptbr'">{{`${dataDespesa.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}}</div>
                            <div v-else>{{`CLP ${dataDespesa.valor.toLocaleString('es-CL', {  currency: 'CLP' , minimumFractionDigits: 2})}`}}</div>
                          </div>
                      </div>
                      <div class="motivo-container" v-if="dataDespesa.statusDespesa >=5 && dataDespesa.dataMotivoRecusa !== null">
                        <div>
                          <div class="mb-2"> <b>Data da Reprova :</b> {{ dataDespesa.dataMotivoRecusaFormatada }}</div>
                          <div>
                            <div class="mr-2 mb-1"> <b>Motivo :</b> {{ dataDespesa.motivoRecusa !== null ? dataDespesa.motivoRecusa : "Sem Motivo" }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="totalDia-container">
                        <div class="titulo-container">{{ $t('consultation.total_daily') }}</div>
                        <div class="gasto-container-negativo"> {{calTotal(expense)}} </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
      </div>
</template>
<script>
import Historico from './Historico'
export default Historico
</script>
<style scoped>
.justifique{
  display: flex;
}
.alinha-fim{
  width: 100%;
  justify-content: end;
  color: rgb(252, 182, 8);
  font-weight: bold;
}
.alinha-inicio{
  justify-content: initial;
}
.pesquisa-title{
  color: #676767;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.pesquisa-container{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.subcategoria-container{
  position: relative;
  padding-left: 18px;
}
.pesquisa-card {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 0 10px;
  gap: 1.5rem;
  width: 100%;
}
.icone-card{
  background-color:#FFFFFF;
  border-radius: 4px;
  padding: 2%;
  width: 15%;
}
.statusdespesas{
  position: absolute;
  left: 0px;
  width: 10px;
  border-radius: 10px 0px 0px 10px;
  height: 100%;
}
.search-line {
    height: 60%;
    width: 1px;
    background-color: #b3b3b369;;
}
.search-input {
    border: none;
    padding: 5px;
    border-radius: 4px;
    font-size: 16px;
    background-color: transparent;
}
.button-filtro{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.497' height='17.497' viewBox='0 0 17.497 17.497'%3E%3Cpath id='icone_filtro' data-name='icone filtro' d='M16.676,0H.821a.821.821,0,0,0-.58,1.4l6.32,6.321v7.042a.82.82,0,0,0,.35.672l2.734,1.913a.821.821,0,0,0,1.29-.672V7.721L17.256,1.4A.821.821,0,0,0,16.676,0Z' fill='%23008148'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border-color: #008148;
  color: #008148;
  border: 1px solid;
  padding-bottom: 6%;
  padding-left: 9%;
}
.data-container{
  color: #676767;
  font-size: 14px;
  font-weight: 600;
  margin: 20px auto;
}
.data-card-container{
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 1px 1px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}
.refeicao-container, .hospedagem-container, .totalDia-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #70707011;
    align-items: center;
}

.motivo-container {
    display: flex;
    border-bottom: 1px solid #70707011;
    padding: 10px 0;
}
.gasto-container-negativo {
    font-size: 14px;
    font-weight: 600;
    color: #828282;
}

.gasto-container-positivo{
    font-size: 14px;
    font-weight: 600;
    color: #008148;
}
.gasto-container{
  font-size: 14px;
  font-weight: 600;
  color: #474747;
}
.total-container{
  background-color: #ECECEC;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #70707011;
}
.titulo-total{
  color: #828282;
  font-weight: 600;
}
.gasto-container-total{
  color: #828282;
  font-weight: 600;
}
.form-filtro{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ffffff;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.titulo-container{
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
}
</style>
